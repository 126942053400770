import * as React from 'react';

const HalloweenSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={25} height={24} fill="none" {...props}>
        <g
            id="halloween_svg__Halloween"
            clipPath="url(#halloween_svg__clip0_1935_2086)"
            stroke="#0D0029"
            strokeWidth={1.035}
            strokeLinejoin="round"
        >
            <path
                id="halloween_svg__Vector"
                d="M23.992 16.042c-2.713.978-3.922 1.51-4.71 3.72-2.593-1.027-3.946-.154-5.258 2.293-.257-2.805-5.667-.833-6.628 1.461.961-2.294-1.428-7.533-3.607-5.749.824-2.652.497-4.228-2.054-5.356 1.024-2.112.555-3.347-.651-5.967 3.212.938 4.639 1.87 6.533 4.236-.751 2.789-.602 4.054 1.109 5.56l1.039-2.347.654 1.173.975.409 1.295-.357-1.039 2.346c2.273.164 3.28-.616 4.74-3.109 1.664-.17 2.916-.136 4.194.203"
                strokeLinecap="round"
            />
            <path
                id="halloween_svg__Subtract"
                d="M18.21 2.932c1.204.795 1.764 2.211 1.265 3.42-.5 1.21-1.895 1.82-3.31 1.535.328.392.756.714 1.265.925 1.621.668 3.456-.053 4.1-1.612.642-1.558-.15-3.364-1.772-4.032a3.262 3.262 0 00-1.548-.236z"
            />
        </g>
        <defs>
            <clipPath id="halloween_svg__clip0_1935_2086">
                <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

HalloweenSvg.displayName = 'HalloweenSvg';
export default HalloweenSvg;
