export type ColorTheme = {
    primary: string;
    secondary: string;
    background: string;
};

export const EDGY = 'edgy';
export const SMART = 'smart';
export const VIBRANT = 'vibrant';
export const MELLOW = 'mellow';
export const TRANSPARENT = 'transparent';
export const HALLOWEEN = 'halloween';

export const EDGY_THEME: ColorTheme = {
    primary: '#FC86BC',
    secondary: '#FFF58C',
    background: '#240F4C',
};

export const SMART_THEME: ColorTheme = {
    primary: '#EFEDAB',
    secondary: '#CDDFD6',
    background: '#044F60',
};

export const VIBRANT_THEME: ColorTheme = {
    primary: '#F7F154',
    secondary: '#8FF2F7',
    background: '#0124E9',
};

export const MELLOW_THEME: ColorTheme = {
    primary: '#FAAFA0',
    secondary: '#FFE6BE',
    background: '#324B8C',
};

export const TRANSPARENT_THEME: ColorTheme = {
    primary: '#3A3247',
    secondary: '#585163',
    background: 'transparent',
};

export const HALLOWEEN_THEME: ColorTheme = {
    primary: '#FFE6BE',
    secondary: '#1E0A3C',
    background: '#F05537',
};

export const COLOR_MAP: Record<string, ColorTheme> = {
    [EDGY]: EDGY_THEME,
    [SMART]: SMART_THEME,
    [VIBRANT]: VIBRANT_THEME,
    [MELLOW]: MELLOW_THEME,
    [TRANSPARENT]: TRANSPARENT_THEME,
    [HALLOWEEN]: HALLOWEEN_THEME,
};
